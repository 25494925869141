import * as React from "react";
import pace350Img from "../images/pace-350-specs.jpg";
import pace350Video from "../images/Pace_350_Video.mp4";
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginTop: "-23px",
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-4px",
  marginBottom: 0,
  width: "100%",
};

// markup
const Sinch = () => {
  return (
    <main style={pageStyles}>
      <title>Pace 350 Specs</title>
      <img style={imageStyles} src={pace350Img} />
      <video
        style={videoStyles}
        crossOrigin="anonymous"
        controls={false}
        autoPlay
        loop
        muted
      >
        <source src={pace350Video} type="video/mp4" />
      </video>
    </main>
  );
};

export default Sinch;
